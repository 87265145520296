import React from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "../../components/layout";
import { PageHeaderSection } from "../../components/rebrand/sections/PageHeader/PageHeaderSection";
import { SEO } from "../../components/seo";
import { IndustryPageData, ZigZagSectionDataRetail } from "../../data/industry";
import { ZigZagSection } from "../../components/rebrand/sections/ZigZag/ZigZag";

const { initialNavigationTheme, industryRetailPageTitleData } = IndustryPageData;

interface RetailPageProps extends PageProps {
  data: Queries.RetailPageQuery;
}

export const pageQuery = graphql`
  query RetailPage {
    site {
      siteMetadata {
        pages {
            industry {
                retail {
                    title
                    description
                    keywords
                }
            }
        }
      }
    }
  }
`;

export default function IndustryMiningPage(props: RetailPageProps) {
  const {
    location: { pathname },
    data:  { site }
  } = props;

  const { title, description, keywords } = site?.siteMetadata?.pages?.industry?.retail as Queries.IndustryRetailPage;

  return (
    <Layout initialNavigationTheme={initialNavigationTheme}>
      <SEO title={title} description={description} keywords={[...keywords]} pathname={pathname} />
      <PageHeaderSection theme={industryRetailPageTitleData.theme} title={industryRetailPageTitleData.title} />
      <ZigZagSection {...ZigZagSectionDataRetail} />
    </Layout>
  );
}
